import React from "react";
import "./Sectionsix.css";

import Svg1 from "../../../assets/img/sponsors/havanna.svg";
import Svg2 from "../../../assets/img/sponsors/jameson.svg";
import Svg3 from "../../../assets/img/sponsors/mivillano.svg";
import Svg4 from "../../../assets/img/sponsors/pedidos-ya.svg";
import Svg5 from "../../../assets/img/sponsors/universal.svg";

function Sectionsix() {
  return (
    <section id="partners" data-scroll="" className="is-inview">
      <div className="wrapper">
        <div className="left">
          <h5 className="little enter-x-r">
            <div className="line delay-2"></div>
            <span>Nuestros partners</span>
          </h5>
        </div>
        <div className="right">
          <div className="partner enter-x delay-1">
            <img src={Svg1} alt="Havanna" />
          </div>
          <div className="partner enter-x delay-2">
            <img src={Svg2} alt="Jameson" />
          </div>
          <div className="partner enter-x delay-3">
            <img src={Svg3} alt="Mi Villano Favorito" />
          </div>
          <div className="partner enter-x delay-4">
            <img src={Svg4} alt="Netflix" />
          </div>
          <div className="partner enter-x delay-5">
            <img src={Svg5} alt="Universal" />
          </div>
          <div className="partner enter-x delay-6">
            <img src={Svg5} alt="Pedidos Ya" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sectionsix;
