import React from "react";
import "./SectionFive.css";

function Sectionfive() {
  return (
    <section id="news-home" data-scroll="" className="is-inview">
      <div className="enter-x">
        <div className="news-mosaic">
          <h2>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
            <span className="ss">NEWS!</span>
            <strong>NEWS!</strong>
          </h2>
        </div>
      </div>

      <div className="news-columns is-inview" data-scroll="">
        <div className="swiper-wrapper">
          <a
            href="/novedades/new-store-neuquen"
            className="swiper-slide new-column"
          >
            <h5 className="little enter-y">
              <div className="line delay-2"></div>
              <span>Aperturas</span>
            </h5>
            <h2 className="enter-y delay-1">
              <strong>new store Neuquén!</strong>
            </h2>
            <p className="enter-y delay-2">
              ¡Bienvenidos a Lucciano's Paseo de la Costa en Neuquén! Nuestra
              nueva sucursal está ubicada en Cabo Segundo López 2119. Te
              ofrecemos una experiencia única en diseño e innovación mientras
              disfrutas de los mejores helados y icepops del país.{" "}
            </p>
            <div className="enter-y delay-3 news-img">
              <div className="bg-column"></div>
            </div>
          </a>

          <a href="/novedades/hey-weston" className="swiper-slide new-column">
            <h5 className="little enter-y delay-4">
              <div className="line delay-6"></div>
              <span>APERTURAS</span>
            </h5>
            <h2 className="enter-y delay-7">
              <strong>HEY WESTON!</strong>
            </h2>
            <p className="enter-y delay-8">
              ¡Bienvenidos a Lucciano’s Weston! Abrimos una nueva sucursal
              ubicada en 1727 Main St, Weston, US para que disfrutes del mejor
              helado del mundo junto a una experiencia diferente.
            </p>
            <div className="enter-y delay-9 news-img">
              <div className="bg-column"></div>
            </div>
          </a>

          <a
            href="/novedades/icepop-argentina"
            className="swiper-slide new-column"
          >
            <h5 className="little enter-y delay-10">
              <div className="line delay-12"></div>
              <span>Lanzamiento</span>
            </h5>
            <h2 className="enter-y delay-11">
              <strong>ICEPOP ARGENTINA</strong>
            </h2>
            <p className="enter-y delay-12">
              En Lucciano's ya nos pusimos la camiseta. Llegó el Icepop
              Argentina ✨🇦🇷 Disponible en sucursales de Argentina y a través de
              Pedidos Ya.
            </p>
            <div className="enter-y delay-13 news-img">
              <div className="bg-column"></div>
            </div>
          </a>
        </div>
        <div className="news-swiper-pagination"></div>
      </div>
    </section>
  );
}

export default Sectionfive;
