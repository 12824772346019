import React from "react";
import "./Secondsection.css";

import svgImg from "../../../assets/img/svgexport-2.svg";
import { Parallax, ParallaxBanner } from "react-scroll-parallax";

class SecondSection extends React.Component {
  constructor(props) {
    super(props);
    this.switchImage = this.switchImage.bind(this);
    this.state = {
      currentImage: 0,
      images: [
        "https://i.ibb.co/nwFqxpG/master-1.png",
        "https://i.ibb.co/t3VHm0s/master-2.png",
        "https://i.ibb.co/D9C3SHZ/master-3.png",
        "https://i.ibb.co/F8nxz4j/master-4.png",
        "https://i.ibb.co/F8nxz4j/master-4.png",
      ],
    };
  }

  switchImage() {
    if (this.state.currentImage < this.state.images.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    } else {
      this.setState({
        currentImage: 0,
      });
    }
    return this.currentImage;
  }

  componentDidMount() {
    setInterval(this.switchImage, 2600);
  }

  render() {
    return (
      <ParallaxBanner style={{ aspectRatio: "2 / 1" }}>
        <section id="masters">
          <div className="wrapper">
            <Parallax speed={8}>
              <h2>
                <span className="ss enter-y delay-1">#The</span>
                <span className="ss enter-y delay-2">Icecream</span>
                <span className="ss enter-y delay-3">Masters</span>
              </h2>
              <div className="masters-logo">
                <img src={svgImg} className="svg"></img>
              </div>
            </Parallax>
            <div className="image-wrap">
              <div className="masters-images">
                <img
                  src={this.state.images[this.state.currentImage]}
                  alt="cleaning images"
                />
              </div>
            </div>
          </div>
          <div className="image-text-button enter-y delay-3">
            <div className="wrapper-button">
              <div className="image-wrap-button">
                <div className="flex">
                  <div className="circle"></div>
                  <a href="" className="button white desktop">
                    <span>Descubri nuestro lab</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ParallaxBanner>
    );
  }
}
export default SecondSection;
