import React from "react";
import "./Sectionfour.css";
import patter from "../../../assets/img/svgexport-5.svg";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  Parallax,
} from "react-scroll-parallax";
import Carrouselinner from "../../../components/CarouselInner/Carouselinner";

function Sectionfour() {
  return (
    <section id="new-store" className="is-inview">
      <div className="new-store-left">
        <h2 className="enter-y">
          <span className="ss">Our stores</span>
        </h2>
        <div className="line">
          <div className="circle white">
            <div className="dot"></div>
          </div>
        </div>
        <p className="big enter-y delay-2">
          Conoce cuál es
          <br />
          el local más cercano
        </p>
      </div>
      <ParallaxBanner
        style={{
          aspectRatio: "2 / 1",
          position: "absolute",
          top: "0",
          right: "0",
          width: "74%",
          height: "650px",
          overflow: "hidden",
          zIndex: "2",
        }}
        className="new-store-main-bg"
      >
        <ParallaxBannerLayer
          image="https://i.ibb.co/S3DLVQX/NORDELTA1.jpg"
          speed={-15}
          expanded={false}
          className="bg parallax "
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            backgroundSize: "cover!important",
            backgroundPosition: "cover!important",
          }}
        />
      </ParallaxBanner>
      <div id="new-store-pattern">
        <img src={patter}></img>
      </div>
      <ParallaxBannerLayer
        speed={25}
        expanded={false}
        className="bg parallax "
        style={{
          position: "absolute",
          top: "0",
          right: "7%",
          padding: "60px",
          paddingBottom: "130px",
          zIndex: "300",
        }}
      >
        <div className="new-store-slide">
          <Carrouselinner></Carrouselinner>
        </div>
      </ParallaxBannerLayer>
      <div className="new-store-button-wrap">
        <a href="locales" className="button">
          <span>Descubri nuestros locales</span>
        </a>
      </div>
    </section>
  );
}

export default Sectionfour;
