import React from "react";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import "./Videotop.css";
import main from "../../../assets/video/main.mp4";
import logobig from "../../../assets/img/logo-big.png";

function Videotop() {
  return (
    <ParallaxBanner style={{ aspectRatio: "2 / 1" }}>
      <ParallaxBannerLayer speed={-20}>
        <div className="overlay">
          <img className="img__overlay" alt="banner" src={logobig} />
        </div>
        <video
          src={main}
          alt="Sahara Desert landscape"
          loading="lazy"
          autoPlay
          loop
          muted
        />
      </ParallaxBannerLayer>
    </ParallaxBanner>
  );
}

export default Videotop;
