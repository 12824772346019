import React, { useContext } from "react";
import { motion } from "framer-motion";
import LoaderContext from "./loaderContext";
import "./Loader.css";
import bg from "../assets/img/Lucciano's.svg";

const LoaderContainer = ({ children }) => {
  const { setIsLoading } = useContext(LoaderContext);
  return (
    <motion.div
      initial={{
        x: 0,
      }}
      animate={{
        x: "100vw",
      }}
      transition={{
        delay: 4.2,
        duration: 0.3,
      }}
      onAnimationComplete={(definition) => {
        setIsLoading(false);
      }}
      className="container-loader"
    >
      <div className="content logo">
        <div className="logo-loader">
          <img src={bg} width={250} />
        </div>
      </div>
    </motion.div>
  );
};

// const useStyles = makeStyles((theme) => ({
//     container: {
//       position: "fixed",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100vh",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "#FF4C29",
//       zIndex: 10000,
//     },
//     content: {
//       margin: "0 auto",
//     },
// }));

export default LoaderContainer;
