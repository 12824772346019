import { Link } from "react-router-dom";
import "./AboutPage.css";
import history from "../assets/img/history/2011_1.jpg";
import React, { useState } from "react";

export const AboutPage = () => {
  const [changeColor, setChangeCOlor] = useState(false);

  const changeColors = () => {
    if (changeColor === "false") {
      setChangeCOlor(true);
    } else {
      setChangeCOlor(false);
    }
  };
  return (
    <section id="history-content">
      <div className="h-tag" data-scroll>
        <p className="enter-y-r delay-5"></p>
      </div>
      <div className="wrapper wrap-mouse-history">
        <div className="mouse">
          <div className="mouse-icon">
            <span className="mouse-wheel"></span>
          </div>
        </div>
      </div>
      <div className="h-flex">
        <div className="h-colum"></div>
        <div className="h-content">
          <div
            id="locales-title"
            className="section-title is-inview"
            data-scroll=""
          >
            <div className="bg-word-wrap enter-x">
              <div className="bg-word">
                <span>KNOW US</span>
              </div>
            </div>
            <h1>
              <div className="row">
                <span className="a-tag enter-x-r delay-4">conocenos</span>
                <span className="b-tag ss enter-y">descubrí </span>
                <span className="b-tag ss enter-y delay-1">nuestra </span>
                <strong className="b-tag enter-y delay-3">historia</strong>
              </div>
            </h1>
          </div>
          <div className="wrapper">
            <div className="h-text is-inview" data-scroll="">
              <p className="enter-y delay-7">
                <strong>
                  Lucciano´s es una empresa familiar que nace a partir del deseo
                  de atender al segmento de consumidores más exigentes de helado
                  artesanal de nuestro país.
                </strong>
              </p>
              <p className="enter-y delay-9">
                Nos fuimos a Italia y trajimos la última técnología para
                fabricar helado y a los mejores maestros heladeros. Combinamos
                las mejores materias primas nacionales e italianas junto a
                chocolates belgas para así desarrollar un producto único,
                inigualable y así nos convertimos en el sinónimo del mejor
                helado Premium del Mercado.
              </p>
            </div>
            <div id="h-images" className="h-images">
              <div className="h-years  desktop is-inview">
                <div className="h-list" data-year="11">
                  <div
                    className="h-y h-select"
                    onChange={changeColors}
                    data-year="11"
                  >
                    2011
                  </div>
                  <div className="h-y" data-year="13">
                    2013
                  </div>
                  <div className="h-y" data-year="15">
                    2015
                  </div>
                  <div className="h-y" data-year="16">
                    2016
                  </div>
                  <div className="h-y" data-year="17">
                    2017
                  </div>
                  <div className="h-y" data-year="19">
                    2019
                  </div>
                  <div className="h-y" data-year="20">
                    2020
                  </div>
                  <div className="h-y" data-year="21">
                    2021
                  </div>
                  <div className="h-y" data-year="22">
                    2022
                  </div>
                </div>
              </div>
              <div className="h-photos-warp">
                {/* <!-- 2011 --> */}
                <div
                  className="h-photos-year"
                  id="y11"
                  data-year="11"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 244.49, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div className="photo">
                            <img className="bg" src={history}></img>
                          </div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>APERTURA DEL PRIMER LOCAL</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 172.9, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div className="photo">
                            <img className="bg" src={history}></img>
                          </div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>NACE LUCCIANO´S EN MAR DEL PLATA</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <img className="bg" src={history}></img>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Apertura de la fábrica</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2011</div>
                </div>

                {/* <!-- 2013 --> */}
                <div
                  className="h-photos-year"
                  id="y13"
                  data-year="13"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 242.2, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <img className="bg" src={history}></img>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nacen los Icepops</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2013_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nacen los Icepops</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <img className="bg" src={history}></img>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nacen los Icepops</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2013</div>
                </div>

                {/* <!-- 2015 --> */}
                <div
                  className="h-photos-year"
                  id="y15"
                  data-year="15"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2015_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>
                            DESEMBARCAMOS CON NUESTRO PRIMER LOCAL EN BUENOS
                            AIRES (MARTINEZ)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum is-inview"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2015_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nace Tonio Icepop</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2015_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>
                            Empezamos a desarrollar nuestros productos en Italia
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2015</div>
                </div>

                {/* <!-- 2016 --> */}
                <div
                  className="h-photos-year"
                  id="y16"
                  data-year="16"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2016_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Apertura del Inspiration LAB</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2016_4.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Apertura del Inspiration LAB</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2016_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Apertura del Inspiration LAB</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2016_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Apertura del Inspiration LAB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2016</div>
                </div>

                {/* <!-- 2017 --> */}
                <div
                  className="h-photos-year"
                  id="y17"
                  data-year="17"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2017_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nace Minion Icepop</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2017_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>
                            Apertura del primer local fuera de la Prov. de
                            Buenos Aires (Rosario)
                          </p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2017_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Nace Minion Icepop</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2017</div>
                </div>

                {/* <!-- 2019 --> */}
                <div
                  className="h-photos-year"
                  id="y19"
                  data-year="19"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2019_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Premio al mejor helado de CABA</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2019_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Premio al mejor helado de CABA</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2019</div>
                </div>

                {/* <!-- 2020 --> */}
                <div
                  className="h-photos-year"
                  id="y20"
                  data-year="20"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Desembarcamos en Estados Unidos</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_4.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Desembarcamos en Estados Unidos</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_6.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LUCCIANO´S US</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Desembarcamos en Estados Unidos</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>Desembarcamos en Estados Unidos</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-3">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_5.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LUCCIANO´S US</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-4">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2020_7.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LUCCIANO´S US</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2020</div>
                </div>

                {/* <!-- 2021 --> */}
                <div
                  className="h-photos-year"
                  id="y21"
                  data-year="21"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>10 años</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_4.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN URUGUAY</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_6.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LANZAMIENTO&nbsp;LUCCIANO'S MARKET</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_8.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>EL BOTIN ES HELADO</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>INICIO DE OBRA INSPIRATION LAB</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN URUGUAY</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-3">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_5.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN URUGUAY</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-4">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2021_7.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>NOS ASOCIAMOS CON NETFLIX</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2021</div>
                </div>

                {/* <!-- 2022 --> */}
                <div
                  className="h-photos-year"
                  id="y22"
                  data-year="22"
                  data-scroll=""
                  data-scroll-repeat="true"
                  data-scroll-offset="300"
                >
                  <div className="h-photos">
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="4"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_2.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LANZAMIENTO LINEA RAW</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_4.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN ROMA</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_6.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN ROMA</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_8.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>ATERRIZAMOS EN BARCELONA</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_10.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>ATERRIZAMOS EN BARCELONA</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-p-colum"
                      data-scroll=""
                      data-scroll-speed="2"
                      // style="transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);"
                    >
                      <div className="h-p-row enter-y delay-1">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_1.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>LANZAMIENTO LINEA RAW</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-2">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_3.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>NACE LIBERTY ICEPOP</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-3">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_5.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>DESEMBARCAMOS EN ROMA</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-4">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_7.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>NACE COLISEO ICEPOPS</p>
                        </div>
                      </div>
                      <div className="h-p-row enter-y delay-5">
                        <div className="photo">
                          <div
                            className="bg"
                            // style="background-image: url(/assets/img/history/2022_8.jpg)"
                          ></div>
                        </div>
                        <div className="photo-name">
                          <div className="p-line">
                            <span></span>
                            <span></span>
                          </div>
                          <p>ATERRIZAMOS EN BARCELONA</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-year">2022</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
