import Footer from "./Homepage/Footer/Footer";
import SecondSection from "./Homepage/Secondsection/SecondSection";
import Sectionfive from "./Homepage/SectionFive/Sectionfive";
import Sectionfour from "./Homepage/SectionFour/Sectionfour";
import Sectionsix from "./Homepage/SectionSix/Sectionsix";
import SectionThree from "./Homepage/SectionThree/SectionThree";
import Videotop from "./Homepage/Videotop/Videotop";

export const HomePage = () => {
  return (
    <div>
      <main>
        <Videotop></Videotop>
        <SecondSection></SecondSection>
        <SectionThree></SectionThree>
        <Sectionfour></Sectionfour>
        <Sectionfive></Sectionfive>
        <Sectionsix></Sectionsix>
        <Footer></Footer>
      </main>
    </div>
  );
};
