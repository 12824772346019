import React from "react";
import "./SectionThree.css";
import Carousel from "../../../components/Carousel/Carousel";
import CarouselTwo from "../../../components/Carousel/Carouseltwo";

function SectionThree() {
  return (
    <section id="featured-products">
      <div className="wrapper is-inview">
        <h2>
          <span className="ss enter-y delay-1">#The</span>
          <span className="ss enter-y delay-2">Icecream</span>
          <strong className="s  s enter-y delay-3">Masters</strong>
        </h2>
        <div className="products-slide-title first-title">
          <h5 className="enter-x delay-1">
            <div className="line delay-3"></div>
            <span>Ice Cream</span>
          </h5>
        </div>
      </div>
      <Carousel></Carousel>
      <div
        className="wrapper is-inview"
        data-scroll=""
        data-scroll-offset="100"
      >
        <div className="products-slide-title">
          <h5 className="enter-x delay-2">
            <div className="line delay-4"></div>
            <span>IcePops</span>
          </h5>
        </div>
      </div>
      <CarouselTwo></CarouselTwo>
    </section>
  );
}

export default SectionThree;
