import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AboutPage } from "./pages/AboutPage";
import { HomePage } from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { UsersPage } from "./pages/UsersPage";
import { UserPage } from "./pages/UserPage";
import { Dashboard } from "./pages/Dashboard";
import "./App.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Menu from "./pages/Homepage/Menu";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoaderContainer from "./screens/LoaderContainer";

const App = () => {
  return (
    <BrowserRouter>
      <LoaderContainer></LoaderContainer>
      <Menu />
      <ParallaxProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/history" element={<AboutPage />} />
          <Route path="/users" element={<UsersPage />} />
          {/* redirect es navigate (el replace sirve para que no retorne a la anterior que reemplazo) */}
          <Route path="/usuarios" element={<Navigate replace to="/users" />} />
          <Route path="/users/:id" element={<UserPage />} />
          <Route path="/users/new" element={<p>New User</p>} />
          {/* ruta con rutas hijas o subrutas */}
          <Route path="/dashboard/" element={<Dashboard />}>
            <Route path="welcome" element={<p>Welcome</p>} />
            <Route path="goodbye" element={<p>Goodbye</p>} />
          </Route>
          {/* pagina 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ParallaxProvider>
    </BrowserRouter>
  );
};

export default App;
