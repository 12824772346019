import React, { useState } from "react";
import "../../components/Navbar.css";
import Nabvar from "../../components/Navbar";
import "./Menu.css";

function Menu() {
  // Show menu burguer on clik
  const [menuActive, setMenuActive] = useState(false);
  const [show, setShow] = useState(true);

  // Sticky scroll nav
  const [navbar, setNavbar] = useState(true);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  window.addEventListener("scroll", changeBackground);

  // Router
  const items = [
    { value: "Home", href: "/home" },
    { value: "About", href: "/about" },
    { value: "Contact", href: "/contact" },
  ];

  return (
    <div>
      <header>
        <nav className={navbar ? "navbar active" : "navbar"}>
          <li className="title-maestro">#ILMAESTRODELGELATO</li>
          <li className="title-maestro-right">Es</li>
          <div
            className={"burger-btn"}
            onClick={() => setMenuActive(!menuActive)}
          >
            <span onClick={() => setShow(!show)}>{show ? "=" : "X"}</span>
          </div>
        </nav>
      </header>
      <Nabvar
        active={menuActive}
        setActive={setMenuActive}
        header={"Menu"}
        items={items}
      />
    </div>
  );
}

export default Menu;
