import React, { Component } from "react";
import Slider from "react-slick";
import "./Options.css";
import One from "../../assets/img/file-1605793992679.png";
import Two from "../../assets/img/file-1605795729875.png";
import Three from "../../assets/img/file-1605795746859.png";
import Four from "../../assets/img/file-1605795783436.png";
import Five from "../../assets/img/file-1605795856950.png";
import Six from "../../assets/img/file-1605796102869.png";
import Seven from "../../assets/img/file-1605796132277.png";
import Eight from "../../assets/img/file-1605796255511.png";

import Nine from "../../assets/img/file-1605796999756.png";
import Ten from "../../assets/img/file-1605801868422.png";
import Eleven from "../../assets/img/file-1608320456151.png";
import Twelve from "../../assets/img/file-1608320787259.png";
import Thridteen from "../../assets/img/file-1608320901889.png";
import Fourteen from "../../assets/img/file-1608321231109.png";

import { Parallax } from "react-scroll-parallax";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function LeftNavButton(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow-left"
      style={{
        ...style,
        fontSize: "40px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

function RightNavButton(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow-right"
      style={{
        ...style,
        fontSize: "40px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}
export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: <RightNavButton onMouseMove={this.play} />,
      prevArrow: <LeftNavButton />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <section className="carrousel">
        <Parallax translateX={["100px", "-200px"]}>
          <Slider ref={(slider) => (this.slider = slider)} {...settings}>
            <div className="space__ice">
              <img src={One} className="float-image" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Helado de crema a base de dulce de leche Premium Argentino,
                    veteado con dulce de leche exclusivo de Lucciano's.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Two} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Three} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Four} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Five} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Six} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Seven} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Eight} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Nine} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Ten} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Eleven} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Twelve} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Thridteen} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Fourteen} />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </Parallax>
      </section>
    );
  }
}
