import React from "react";
import validate from "./validateInfo";
import useForm from "./useForm";
import "../../Footer/Footer.css";

const FormSignup = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  return (
    <>
      {/* Form to arrange */}
      <form className="newsletter-form" onSubmit={handleSubmit} noValidate>
        <div className="newsletter-message nm-success">
          <span>
            <i className="fal fa-check-circle"></i> ¡Muchas gracias por
            suscribirte!
          </span>
        </div>
        <div className="newsletter-message nm-error">
          <span>
            <i className="fal fa-exclamation-circle"></i> Hubo un error. Por
            favor intenta nuevamente.
          </span>
        </div>
        <p className="big">Subscribite a nuestro newsletter</p>
        <div className="input">
          <label htmlFor="news-name">Nombre</label>
          <input
            type="text"
            name="username"
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className="input">
          <label htmlFor="news-email">Email</label>
          <input
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <button type="submit" className="button white">
          <span>Suscribirme</span>
        </button>
      </form>
    </>
  );
};

export default FormSignup;
