import React from "react";
import Ice from "../../assets/img/file-1605793992679.png";
import Ice2 from "../../assets/img/file-1605795729875.png";
import "./ChangeHover.css";

export const ChangeHover = () => {
  return (
    <div class="main-menu-images">
      <div className="menu-images-wrapper">
        <div className="menu__item">
          <a className="menu__item-link menu-item ">
            <span>Historia</span>
          </a>
          <img className="menu__item-img" src={Ice} alt="Some image" />
        </div>
        <div class="menu__item">
          <a
            href="/locales"
            className="menu-item menu__item-link enter-x delay-7"
            data-menu-id="5"
          >
            <span>Locales</span>
          </a>
          <img class="menu__item-img" src={Ice2} alt="Some image" />
        </div>
        <a
          href="/inspiration-lab"
          className="menu-item enter-x delay-8 lab-menu-button"
          data-menu-id="8"
        >
          <span>Inspiration Lab</span>
        </a>
      </div>
    </div>
  );
};
