import React, { Component } from "react";
import Slider from "react-slick";
import "./Options.css";
import One from "../../assets/img/lilipops/file-1603919700456.png";
import Two from "../../assets/img/lilipops/file-1604351795340.png";
import Three from "../../assets/img/lilipops/file-1604353935248.png";
import Four from "../../assets/img/lilipops/file-1604354176141.png";
import Five from "../../assets/img/lilipops/file-1604355503675.png";
import Six from "../../assets/img/lilipops/file-1604355884336.png";
import Seven from "../../assets/img/lilipops/file-1604356660438.png";
import Eight from "../../assets/img/lilipops/file-1605797538930.png";

import Nine from "../../assets/img/lilipops/file-1605797845032.png";
import Ten from "../../assets/img/lilipops/file-1605797897247.png";
import Eleven from "../../assets/img/lilipops/file-1620737153038.png";
import Twelve from "../../assets/img/lilipops/file-1620737430413.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { Parallax } from "react-scroll-parallax";

function LeftNavButton(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow-left"
      style={{
        ...style,
        fontSize: "40px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
}

function RightNavButton(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow-right slick"
      style={{
        ...style,
        fontSize: "40px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}
export default class CarouselTwo extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      nextArrow: <RightNavButton onMouseMove={this.play} />,
      prevArrow: <LeftNavButton />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="carrousel">
        <Parallax translateX={["-100px", "200px"]}>
          <Slider ref={(slider) => (this.slider = slider)} {...settings}>
            <div className="space__ice">
              <img src={One} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Two} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Three} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Four} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Five} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Six} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>{" "}
            </div>
            <div className="space__ice">
              <img src={Seven} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Eight} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Nine} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Ten} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Eleven} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
            <div className="space__ice">
              <img src={Twelve} className="space__lilipops  product-thumb" />
              <div className="product">
                <div className="product-text">
                  <h2>STRAWBERRY AND CREAM</h2>
                  <p>
                    Made with fresh strawberries from our region and some
                    delicious fresh orange juice.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </Parallax>
      </section>
    );
  }
}
