import React, { Component } from "react";
import Slider from "react-slick";
import "./Carrouselinner.css";
import Slide1 from "../../assets/img/hooper/new-store-slide-1.jpg";
import Slide2 from "../../assets/img/hooper/new-store-slide-2.jpg";
import Slide3 from "../../assets/img/hooper/new-store-slide-3.jpg";
import Slide4 from "../../assets/img/hooper/new-store-slide-4.jpg";

import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

function RightNavButtons(props) {
  const { style, onClick } = props;
  return (
    <div
      className="button white swiper-button-next"
      style={{
        ...style,
        fontSize: "40px",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
}

export default class Carrouselinner extends Component {
  // state counting Slide Change Hooks
  state = {
    activeSlide2: 4,
  };
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <RightNavButtons />,
      autoplay: true,
      autoplaySpeed: 4000,

      // Change Hooks
      afterChange: (next) => this.setState({ activeSlide2: next }),
    };
    return (
      <section className="carrousel-slider">
        <Slider {...settings}>
          <div>
            <img src={Slide1} alt="" />
          </div>
          <div>
            <img src={Slide2} alt="" />
          </div>
          <div>
            <img src={Slide3} alt="" />
          </div>
          <div>
            <img src={Slide4} alt="" />
          </div>
        </Slider>
        {/* Change Hooks */}
        <div className="circle white">
          <div className="dot"></div>
        </div>
        <div className="pagination" id="new-store-pagination">
          <div className="current">{this.state.activeSlide2}</div>
          <div className="total">
            <span>4</span>
          </div>
        </div>
      </section>
    );
  }
}
