import React from "react";
import "../Footer.css";

const FormSuccess = () => {
  return (
    <div>
      <h1 className="form-success">We have received your request!</h1>
    </div>
  );
};

export default FormSuccess;
