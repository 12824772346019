import { NavLink } from "react-router-dom";

import React from "react";

import "./Navbar.css";
import { ChangeHover } from "./ChangeHover/ChangeHover";

function Menu({ header, items, active, setActive }) {
  let [over, setOver] = React.useState(false);

  let buttonstyle = {
    backgroundColor: "",
  };

  if (over) {
    buttonstyle.backgroundColor = "green";
  } else {
    buttonstyle.backgroundColor = "";
  }

  return (
    <div
      className={active ? "menu active" : "menu"}
      onClick={() => setActive(false)}
    >
      <div class="main-menu-inner wrapper" onClick={(e) => e.stopPropagation()}>
        <NavLink
          className={({ isActive }) => +isActive && "active"}
          to="/history"
        >
          <a
            href="/history"
            class="menu-item enter-x delay-4"
            data-menu-id="4"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          >
            <span>Historia</span>
          </a>
        </NavLink>

        <a
          href="/productos?c=icepops"
          class="menu-item enter-x delay-5"
          data-menu-id="1"
        >
          <span>IcePops</span>
        </a>
        <a
          href="/productos?c=icecreams"
          class="menu-item enter-x delay-6"
          data-menu-id="2"
        >
          <span>Ice cream</span>
        </a>
        <a href="/locales" class="menu-item enter-x delay-7" data-menu-id="5">
          <span>Locales</span>
        </a>
        <a
          href="/inspiration-lab"
          class="menu-item enter-x delay-8 lab-menu-button"
          data-menu-id="8"
        >
          <span>Inspiration Lab</span>
        </a>
        <a
          href="/party-service"
          class="menu-item enter-x delay-9 active"
          data-menu-id="9"
        >
          <span>Party Service</span>
        </a>
        <a
          href="/franquicias"
          class="menu-item enter-x delay-10"
          data-menu-id="6"
        >
          <span>Franquicias</span>
        </a>
        <a href="/contacto" class="menu-item enter-x delay-11" data-menu-id="7">
          <span>Contacto</span>
        </a>

        <a
          href="/novedades"
          class="menu-item enter-x delay-12"
          data-menu-id="10"
        >
          <span>Novedades</span>
        </a>
      </div>
      <ChangeHover></ChangeHover>
      {/* <ul>
          <li>
            <NavLink className={({ isActive }) => +isActive && "active"} to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => +isActive && "active"}
              to="/about"
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => +isActive && "active"}
              to="/users"
            >
              Users
            </NavLink>
          </li>
        </ul> */}
    </div>
  );
}

export default Menu;
